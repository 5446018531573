import $ from 'jquery';

$(function() {
    
    // Intro page link
    $('body.page-intro .site-wrapper').on('click', function(){
        location.href = '/overview';
    });

    // Section Cards
    $('body.page-overview .section-card').on('click', function(){
        location.href = $(this).data('url');
    });

    // Slide Swapping
    $('.page-content_slides img').on('click', function(e) {
        e.preventDefault();

        if ( $(this).hasClass('slide-active') ) return;

        let full_src = $(this).data('full-src');

        $('.page-content_image img.child-image').fadeOut('fast', function(){
            $(this).attr( 'srcset', full_src ).fadeIn('slow');
        });

        $('.page-content_slides img').not(this).removeClass('slide-active');
        $(this).addClass('slide-active');

    });

});